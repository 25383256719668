import { Design } from '../types';
import { getCanvas, getDesignOutputImageFromJson, getPreviewImage } from './canvas-export';

export const getTemplateSideWithPreviewImage = (template, side, color?) => {
  const { initialCanvasState } = side;

  if (!initialCanvasState) {
    return Promise.resolve(side);
  }

  const canvas = getCanvas(side);

  return getDesignOutputImageFromJson(canvas, initialCanvasState).then((designOutputImage) => {
    return getPreviewImage(
      designOutputImage,
      template,
      side,
      color ? color.id : template.colors[0].id
    ).then((previewImage) => ({
      ...side,
      previewImage,
    }));
  });
};

export const getTemplateWithPreviewImage = (template) => {
  const { sides } = template;

  const colors = template.colors;
  const side = sides[0];

  const { initialCanvasStateUrl } = side;

  if (!initialCanvasStateUrl || !colors[0]) {
    return Promise.resolve(template);
  }

  return fetch(initialCanvasStateUrl)
    .then((response) => response.text())
    .then((canvasState) => {
      const canvas = getCanvas(side);

      return getDesignOutputImageFromJson(canvas, canvasState).then((designOutputImage) => {
        return getPreviewImage(designOutputImage, template, side, colors[0].id).then(
          (previewImage) => ({
            ...template,
            previewImage,
          })
        );
      });
    });
};

export const getTemplateWithInitialCanvasStatesFromTemplate = (template) => {
  const { sides } = template;

  return Promise.all(
    sides.map((side) => {
      const { initialCanvasStateUrl } = side;

      if (!initialCanvasStateUrl) {
        return side;
      }

      return fetch(initialCanvasStateUrl)
        .then((response) => response.text())
        .then((initialCanvasState) => ({ ...side, initialCanvasState }))
        .catch(() => side);
    })
  ).then((newSides) => ({ ...template, sides: newSides }));
};

export const getTemplateWithInitialCanvasStatesFromDesign = (design: Design) => {
  const { sides: designSides } = design;

  return Promise.all(
    designSides.map((designSide) => {
      const { templateSide } = designSide;
      const { initialCanvasStateUrl } = templateSide;

      if (!initialCanvasStateUrl) {
        return templateSide;
      }

      return fetch(initialCanvasStateUrl)
        .then((response) => response.text())
        .then((initialCanvasState) => ({ ...templateSide, initialCanvasState }))
        .catch(() => templateSide);
    })
  ).then((newSides) => ({ ...design.template, sides: newSides }));
};
