import axios from 'axios';

import { sortBy } from 'lodash';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { Template, TemplateColor, TemplateColorImage, TemplateSide } from '@/components/types';
import {
  getTemplateWithPreviewImage,
  getTemplateWithInitialCanvasStatesFromTemplate,
} from '@/lib/utils/template-preview';
import { TemplatePreviewImage } from '@/lib';
import { useToast } from '@chakra-ui/react';

const entity = 'templates';

const URL = `/${entity}`;

export interface IGetTemplateOptions {
  clientId?: string;
  isFeatured?: boolean;
  isDefault?: boolean;
}

const orderTemplateSidesColorsAndPreviewImages = (template) => {
  const { colors, previewImages, sides } = template;

  return {
    ...template,
    sides: sortBy(sides, ({ order }) => order),
    colors: sortBy(colors, ({ order }) => order),
    previewImages: sortBy(previewImages, ({ order }) => order),
  };
};

export const getTemplatesWithPreviewImages = (params?: IGetTemplateOptions) =>
  getTemplates(params).then((templates) => Promise.all(templates.map(getTemplateWithPreviewImage)));

export const getDefaultTemplates = () =>
  axios
    .get<Template[]>(URL + '?isDefault=true')
    .then(({ data }) => data.map(orderTemplateSidesColorsAndPreviewImages));

export const getTemplates = (params?: IGetTemplateOptions) =>
  axios
    .get<Template[]>(URL, { params })
    .then(({ data }) => data.map(orderTemplateSidesColorsAndPreviewImages));

export const useBasicTemplates = (params?: IGetTemplateOptions) =>
  useQuery([entity, 'basic'], () => getTemplates(params));

export const useTemplates = (params?: IGetTemplateOptions) =>
  useQuery([entity], () => getTemplatesWithPreviewImages(params));

export const getTemplate = (id: string) =>
  axios
    .get<Template>(`${URL}/${id}`)
    .then(({ data }) =>
      getTemplateWithPreviewImage(orderTemplateSidesColorsAndPreviewImages(data)).then(
        getTemplateWithInitialCanvasStatesFromTemplate
      )
    );

export const useTemplate = (id: string) => useQuery([entity, id], () => getTemplate(id));

export const createTemplate = (template: Template): Promise<Template> => {
  return axios.post<Template>(`${URL}/full`, template).then(({ data }) => data);
};

export const updateTemplate = (template: Template) => {
  return axios.patch<Template>(`${URL}/${template.id}`, template).then(({ data }) => data);
};

export const saveTemplate = (template: Template) => {
  const method = template.id ? updateTemplate : createTemplate;

  return method(template);
};

export const deleteTemplate = (id) => axios.delete(`${URL}/${id}`);

export const useDeleteTemplate = () => {
  const client = useQueryClient();

  const toast = useToast();

  const { isLoading, mutate: removeTemplate } = useMutation((id: string) => deleteTemplate(id), {
    onSuccess: () => {
      client.invalidateQueries([entity]);

      toast({
        position: 'top',
        title: 'Template successfully deleted',
        status: 'success',
        duration: 1000,
      });
    },
  });

  return {
    isDeleting: isLoading,
    removeTemplate,
  };
};

export const createTemplateSide = (templateId: string, side: TemplateSide): Promise<TemplateSide> =>
  axios.post<TemplateSide>(`${URL}/${templateId}/sides`, side).then(({ data }) => data);

export const updateTemplateSide = (
  templateId: string,
  updates: Partial<TemplateSide>
): Promise<TemplateSide> =>
  axios
    .patch<TemplateSide>(`${URL}/${templateId}/sides/${updates.id}`, updates)
    .then(({ data }) => data);

export const deleteTemplateSide = (templateId, sideId) =>
  axios.delete(`${URL}/${templateId}/sides/${sideId}`);

export const createTemplateColor = (
  templateId: string,
  color: TemplateColor
): Promise<TemplateColor> =>
  axios.post<TemplateColor>(`${URL}/${templateId}/colors`, color).then(({ data }) => data);

export const updateTemplateColor = (
  templateId: string,
  color: TemplateColor
): Promise<TemplateColor> =>
  axios
    .patch<TemplateColor>(`${URL}/${templateId}/colors/${color.id}`, color)
    .then(({ data }) => data);

export const deleteTemplateColor = (templateId, colorId) =>
  axios.delete(`${URL}/${templateId}/colors/${colorId}`);

export const createTemplateColorImage = (templateId, image) =>
  axios.post<TemplateColorImage>(`${URL}/${templateId}/images`, image).then(({ data }) => data);

export const updateTemplateColorImage = (templateId, imageId, image) =>
  axios
    .patch<TemplateColorImage>(`${URL}/${templateId}/images/${imageId}`, { image })
    .then(({ data }) => data);

export const deleteTemplatePreviewImage = (templateId, imageId) =>
  axios.delete(`${URL}/${templateId}/preview-images/${imageId}`);

export const createTemplatePreviewImage = (templateId, previewImage: TemplatePreviewImage) =>
  axios
    .post<TemplatePreviewImage>(`${URL}/${templateId}/preview-images`, previewImage)
    .then(({ data }) => data);

export const updateTemplatePreviewImage = (
  templateId,
  imageId,
  updates: Partial<TemplatePreviewImage>
) =>
  axios
    .patch<TemplatePreviewImage>(`${URL}/${templateId}/preview-images/${imageId}`, updates)
    .then(({ data }) => data);
